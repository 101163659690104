module.exports = [{
      plugin: require('/home/runner/work/landing/landing/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-61133305-4","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/home/runner/work/landing/landing/node_modules/gatsby-plugin-google-gtag/gatsby-browser'),
      options: {"plugins":[],"trackingIds":["G-QLE6KY42PH"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('/home/runner/work/landing/landing/node_modules/gatsby-remark-images/gatsby-browser'),
      options: {"plugins":[],"maxWidth":690,"backgroundColor":"#f7f0eb"},
    },{
      plugin: require('/home/runner/work/landing/landing/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/landing/landing/node_modules/gatsby-plugin-react-i18next/gatsby-browser'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["cy","en","pt"],"defaultLanguage":"en","siteUrl":"https://impactasaurus.org","i18nextOptions":{"interpolation":{"escapeValue":false}}},
    }]
