// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-press-release-tsx": () => import("/home/runner/work/landing/landing/src/templates/press-release.tsx" /* webpackChunkName: "component---src-templates-press-release-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("/home/runner/work/landing/landing/src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-tags-page-tsx": () => import("/home/runner/work/landing/landing/src/templates/tags-page.tsx" /* webpackChunkName: "component---src-templates-tags-page-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("/home/runner/work/landing/landing/src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/runner/work/landing/landing/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/home/runner/work/landing/landing/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("/home/runner/work/landing/landing/src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cookie-tsx": () => import("/home/runner/work/landing/landing/src/pages/cookie.tsx" /* webpackChunkName: "component---src-pages-cookie-tsx" */),
  "component---src-pages-faq-tsx": () => import("/home/runner/work/landing/landing/src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-monitor-tsx": () => import("/home/runner/work/landing/landing/src/pages/features/monitor.tsx" /* webpackChunkName: "component---src-pages-features-monitor-tsx" */),
  "component---src-pages-features-questionnaires-tsx": () => import("/home/runner/work/landing/landing/src/pages/features/questionnaires.tsx" /* webpackChunkName: "component---src-pages-features-questionnaires-tsx" */),
  "component---src-pages-features-reporting-tsx": () => import("/home/runner/work/landing/landing/src/pages/features/reporting.tsx" /* webpackChunkName: "component---src-pages-features-reporting-tsx" */),
  "component---src-pages-features-responses-tsx": () => import("/home/runner/work/landing/landing/src/pages/features/responses.tsx" /* webpackChunkName: "component---src-pages-features-responses-tsx" */),
  "component---src-pages-features-security-tsx": () => import("/home/runner/work/landing/landing/src/pages/features/security.tsx" /* webpackChunkName: "component---src-pages-features-security-tsx" */),
  "component---src-pages-features-segments-tsx": () => import("/home/runner/work/landing/landing/src/pages/features/segments.tsx" /* webpackChunkName: "component---src-pages-features-segments-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/runner/work/landing/landing/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-press-tsx": () => import("/home/runner/work/landing/landing/src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-pricing-tsx": () => import("/home/runner/work/landing/landing/src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("/home/runner/work/landing/landing/src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-signup-tsx": () => import("/home/runner/work/landing/landing/src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-support-tsx": () => import("/home/runner/work/landing/landing/src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-terms-tsx": () => import("/home/runner/work/landing/landing/src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/runner/work/landing/landing/.cache/data.json")

